<template>
	<div class="body">
		<div class="box">
			<p style="">长按转发海报</p>
			<img :src="url" alt="" style="width: 100%;">
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				url: ''
			}
		},
		created() {
			this.url = this.$route.query.url;
			document.title = '合成海报'
		}
	}
</script>

<style lang="less" scoped>
	.body {
		overflow-y: hidden;
	}

	.box {
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		
		p {
			font-size: 20px;
			color: #ccc;
			text-align: center;
			margin: 14px 0;
		}
	}

	.box::-webkit-scrollbar {
		display: none;
	}
</style>
